import React from 'react';
import dataShape from '../../data/dataShape';
import update from 'update-immutable';
import * as _ from 'lodash'
// import {
//   Form
// } from 'semantic-ui-react';
// import { Formik } from 'formik';

import styles from './data-input.module.scss';

const doesObjectMatch = (object, query) => !Object.keys(query).some( key => query[key] !== object[key] )

const setNestedValue = (object, key, value) => {
  if (Array.isArray(object)) {
    return object.map( el => setNestedValue(el, key, value));
  }
  const result = {};
  Object.keys(object).forEach( k => {
    if (k === key) {
      result[key] = value;
    } else if ( typeof object[k] === 'object') {
      result[k] = setNestedValue(object[k], key, value);
    } else {
      result[k] = object[k];
    }
  })
  return result;
}

class DataInputPage extends React.Component {
  state = {
    date: new Date().toISOString().slice(0,10),
    fields: []
  }

  handleChange = ({ target }) => {
    const { date } = this.state;
    const { name, checked, type } = target;
    let { value } = target;
    
    // if (name === "date") return this.setState({ date: value, fields: [] })
    if (name === "date") {
      return this.setDate(value)
    };
    const { person, path, index } = target.dataset;
    if (type === "number") value = +value;
    // console.log("Target:", target, path);
    const record = { person, path, date };
    if (index) { // If this is a member of a list
      return this.insertListValue({ person, date, path }, index, name, value);
    }
    switch (type) {
      case "checkbox":
        this.insertValue({ ...record, value: checked });
        return;
      default:
        this.insertValue({ ...record, value });
    }
  }

  // Changing the result text field directly. Should only be done on blur
  handleChangeResult = ({ target }) => {
    try {
      this.setState({ fields: JSON.parse(target.value) })
    } catch (err) {
      console.error("Failed to parse json input (ignoring changes):", err);
    }
  }

  // Search for a match in all dimensions, and upsert
  insertValue = (value) => {
    const { person, date, path } = value;

    const match = this.findMatchingValue(value);

    // console.log("match?", match ? "yes" : "no");
    // match && console.log(match, "matched", {person, date, path});

    // debugger;
    if (match) {
      this.setState({
        fields: this.state.fields.map( field => 
          doesObjectMatch(field, { person, date, path }) 
            ? value
            : field
        )
      })
    } else {
      this.setState({ fields: this.state.fields.concat([ value ])})
    }
  }

  insertListValue = (field, index, name, value) => {
    const { person, date, path } = field;

    const match = this.findMatchingValue(field);
    
    if (match) { // If the base field value exists, find it and update it with this new index value
      const newField = update(match, { listValues: { [index]: { [name]: { $set: value} }}});
      this.setState({ fields: this.state.fields.map( el => doesObjectMatch(el, field) ? newField : el )});
    } else { // If it doesn't exist, create it with this index
      const newField = { person, date, path, listValues: [ { [name]: value } ]};
      this.setState({ fields: this.state.fields.concat([ newField ])})
    }
  }

  findMatchingValue = ({ person, date, path }) => {
    return this.state.fields.find( field => 
      doesObjectMatch(field, { person, date, path })
    );
  }

  getValue = (query) => {
    const matchingField = this.state.fields.find( el => doesObjectMatch(el, query));
    return matchingField ? matchingField.value : '';
  }

  getListValue = (query, index, name) => {
    const matchingField = this.state.fields.find( el => doesObjectMatch(el, query));
    // return matchingField && matchingField.listValues[index] && [name] : ''; //TODO: needs guarding
    return matchingField ? _.get(matchingField, `listValues.${index}.${name}`) || '' : '';
  }

  getListValues = (query) => {
    const matchingField = this.state.fields.find( el => doesObjectMatch(el, query));
    return matchingField ? matchingField.listValues : [];
  }

  setDate = (date) => {
    this.setState({ date, fields: setNestedValue(this.state.fields, "date", date)});
  }

  render() {
    const { date, fields } = this.state;
    return (
      <div className={styles.container} >
      <form>
        <h1>Data Entry Form</h1>
        <p>
          For date:
          <input name='date' onChange={this.handleChange} value={date}/>
          <button type="button" onClick={() => this.setState({ fields: [] })}>Reset Form</button>
        </p>
        
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Field</th>
              <th>Both of us</th>
              <th>Kalan</th>
              <th>Kendall</th>
            </tr>
          </thead>
          <tbody>
            {dataShape.fields.map(field => (
              <FieldRow field={field} onChange={this.handleChange} key={field.name} path={field.name} getValue={this.getValue} getListValue={this.getListValue} getListValues={this.getListValues}/>
            ))}
          </tbody>
        </table>
        
        <textarea 
          style={{width: "100%", height: 500, fontSize: "0.6rem"}}
          disabled={true}
          value={JSON.stringify(fields, null, 2)}
          />
      </form>
      </div>
    )
  }
}

export default DataInputPage;

const FieldRow = ({ field, path, getValue, getListValue, getListValues, onChange }) => {
  if (field.subFields) {
    return (
      <React.Fragment>
        <tr>
          <td colSpan={4}>
            <b>{field.label}</b>
          </td>
        </tr>
        {field.subFields.map( field => <FieldRow field={field} onChange={onChange} key={field.name} path={`${path}.${field.name}`} getValue={getValue} getListValues={() => {}}/>)}
      </React.Fragment>
    )
  } else {
    if (!field.dimensions || field.dimensions.includes("person")) {
      return (
        <tr>
          <td>
            {field.label}
          </td>
          {[undefined, "kalan", "kendall"].map((person, index) => (
            <td key={index + person}>
              <Field field={field} path={path} onChange={onChange} person={person} value={getValue({ person, path })} listValues={getListValues({ person, path })}/>
            </td>
          ))}
        </tr>
      )
    } else {
      return (
        <tr>
          <td>
            {field.label}
          </td>
          <td colSpan={3}>
            <Field field={field} path={path} onChange={onChange} getListValue={getListValue} value={getValue({ path })} listValues={getListValues({ path })}/>
          </td>
        </tr>
      )      
    }
  }
}

const Field = ({ field, onChange, person, path, value, listValues = [], getListValue, ...props }) => {
  const commonProps = {
    name: field.name,
    onChange,
    "data-person": person,
    "data-path": path,
    value
  }
  let options;
  switch (field.type) {
    case "enum":
      options = [''].concat(field.values).map(v => <option key={v}>{v}</option>);
      return <select {...commonProps} {...props}>{options}</select>
    case "rankedEnum":
      options = [''].concat(field.values).map(v => <option key={v.value} value={v.value}>{v.label}</option>);
      return <select {...commonProps} {...props} type="number">{options}</select>
    case "number":
      return <input type='number' {...commonProps} {...props} />
    case "flag":
      return <input type="checkbox" {...commonProps} {...props} />
    case "list":
      return (
        <React.Fragment>
          {listValues.concat([{}]).map((val, index) => (
            <fieldset key={index}>
              {field.elements.map( el => (
                <span key={el.name}>
                  <label>{el.label}</label>
                  <Field 
                    field={el} 
                    onChange={onChange} 
                    person={person} 
                    path={path} 
                    data-index={index} 
                    value={getListValue({ person, path }, index, el.name)}
                    />
                </span>
              ))}
            </fieldset>
          ))}

        </React.Fragment>
      )
    default:
      return <input {...commonProps} {...props} />
  }
}