const dimensions = [
  { name: "person", label: "Person", type: "enum", values: [ "Kendall", "Kalan" ], required: false},
  { name: "date", label: "Date", type: "datetime", required: true }
]

const emotions = [
  { label: ":(((", value: 1},
  { label: ":((", value: 1.5},
  { label: ":(", value: 2},
  { label: ":/", value: 2.5},
  { label: ":|", value: 3},
  { label: ":)", value: 3.5},
  { label: ":))", value: 4},
  { label: "=D", value: 4.5},
  { label: "=D!", value: 5}
] 

const tags = [
  { name: "beach", label: "Went to the beach" },
  { name: "borderCrossing",  label: "Crossed a border"},
  { name: "bureaucracy",  label: "Dealt with bureaucrats"},
  { name: "blog", label: "Worked on blog/video" },
  { name: "campVan",  label: "Slept in the van (Following Night)" },
  { name: "campTent",  label: "Slept in a tent (Following Night)" },
  { name: "lodging", label: "Slept in a building (Following Night)" },
  { name: "classes",  label: "Took a class" },
  { name: "climbing",  label: "Went rock climbing" },
  { name: "exercise", label: "Got exercise in (heartrate up)"},
  { name: "hiking",  label: "Hiked" },
  { name: "rain",  label: "Rained" },
  { name: "sun",  label: "Was sunny" },
  { name: "shopping", label: "Went shopping" },
  { name: "swimming",  label: "Went swimming"},
  { name: "touristing",  label: "Did the tourist thing"},
  { name: "horsebackRiding",  label: "Rode a horse"},
  { name: "vegetarian", label: "Ate vegetarian" },
  { name: "shower.cold", label: "Took a cold/room temp shower" },
  { name: "shower.hot", label: "Took a hot shower" },
  { name: "learnSkill", label: "Learned a new skill" },
  { name: "sunburn.new", label: "Got sunburnt today"},
  { name: "sunburn.continued", label: "Still sunburnt from earlier"},
  { name: "allergies", label: "Allergies" },
  { name: "headache", label: "Headache", private: true},
  { name: "sick", label: "Sick"}
]

const fields = [
  {
    name: "country",
    label: "Country",
    type: "enum",
    values: [
      "Chile", "Argentina", "Uruguay", "Bolivia", "Peru", "Ecuador", "Paraguay"
    ].sort()
  },
  {
    name: "hoursSleep",
    label: "Hours of Sleep, Night Prior",
    type: "number"
  },
  {
    name: "timeBed",
    label: "Bedtime, Night Prior (hh:mm)",
    type: "string"
  },
  {
    name: "timeWake",
    label: "Wakeup Time (hh:mm)",
    type: "string"
  },
  {
    name: "enthusiasm",
    label: "Enthusiasm",
    subFields: [
      { name: "trip", label: "Enthusiasm for being on this trip in particular", type: "rankedEnum", values: emotions },
      { name: "van", label: "Enthusiasm for living in a van", type: "rankedEnum", values: emotions },
      { name: "location", label: "Enthusiasm for the location (city, country) we're in", type: "rankedEnum", values: emotions }
    ]
  },
  {
    name: "distance",
    label: "Distance",
    subFields: [
      { name: "walking", label: "Distance Walked", type: "number" },
      { name: "driving", label: "Distance Driven", type: "number" }
    ]
  },
  {
    name: "lodgingLocation",
    label: "Lodging",
    subFields: [
      { name: "description", label: "Lodging Description", type: "string" },
      { name: "coordinates", label: "Coordinates - lat, long", type: "string" }
    ]
  },
  {
    name: "moneySpent",
    label: "Money Spent ($)",
    type: "list",
    dimensions: [],
    elements: [
      { name: "description", label: "Description", type: "string" },
      { name: "amount", label: "Amount ($)", type: "number" },
      { name: "category", label: "Category", type: "enum", values: ["capital", "vanFuel", "vanParking", "vanImprovement", "vanMaintenance", "entertainment", "foodIn", "foodOut", "activities", "lodging", "transportation", "other"].sort(), aggregate: true }
    ]
  },
  {
    name: "temperatureHigh",
    label: "High Temp (F)",
    type: "number"
  },
  {
    name: "temperatureLow",
    label: "Low Temp (F)",
    type: "number"
  },
  {
    name: "keyEvents",
    label: "Important Events",
    type: "list",
    dimensions: [],
    elements: [
      { name: "Description", label: "Description", type: "string" }
    ]
  }
].concat(...tags.map( tag => Object.assign({}, tag, { type: "flag" })));

export default {
  dimensions,
  fields
};